<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title">{{ editId?'编辑员工':'添加员工' }}</div>
    <div class="hk_page_wrap">
      <div class="hk-common-page">
        <div class="add-block">
          <el-form label-width="140px" size="mini" :rules="addRules" ref="addForm" :model="addForm">
            <div class="block-title">基本信息</div>
            <div class="hk-flex" style="align-items: flex-start;">
              <div class="hk-flex-item">
                <el-form-item label="classIn姓名：" prop="staffName">
                  <el-input v-model="addForm.staffName" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="classIn手机号：" prop="phone">
                  <template v-if="editId">
                    <el-input v-model.number="addForm.phone" disabled class="hk-ipt-number" placeholder="请输入classIn上课手机号" type="number"></el-input>
                  </template>
                  <template v-else>
                    <el-input v-model.number="addForm.phone" class="hk-ipt-number" placeholder="请输入classIn上课手机号" type="number"></el-input>
                  </template>
                  <span class="hk-font-tint">用于排课</span>
                </el-form-item>
                <el-form-item label="绑定企微：" prop="wxCpUserId">
                  <el-select v-model="addForm.wxCpUserId" filterable placeholder="请选择" style="width:100%;">
                    <el-option :label="item.nickname+'@拾伍营销'" :value="item.wxCpUserId" :disabled="item.bindStatus=='Y'" :key="idx" v-for="(item,idx) in wxCpList">{{item.nickname}}<span class="hk-font-highlight">@拾伍营销</span>{{item.bindStatus==='Y'?' - ' + item.staffName :''}}</el-option>
                  </el-select>
                </el-form-item>

                <!-- <el-form-item label="所授学科：" prop="subjectType">
                  <el-select v-model="addForm.subjectType" placeholder="请选择">
                    <el-option :label="item.configName" :value="item.configValue" :key="idx" v-for="(item,idx) in subjectTypeObj">{{ item.configName}}</el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item label="校区：" prop="schoolZone">
                  <el-radio-group v-model="addForm.schoolZone" v-removeAriaHidden>
                    <el-radio :label="key" :key="key" v-for="(txt,key) in schoolZoneList">{{txt}}</el-radio>

                  </el-radio-group>
                </el-form-item>
                <el-form-item label="毕业院校：" prop="college">
                  <el-input v-model="addForm.college" placeholder=""></el-input>
                </el-form-item>
                <!-- <el-form-item label="邮箱：" prop="email">
                  <el-input v-model="addForm.email" placeholder=""></el-input>
                </el-form-item> -->
                <el-form-item label="教师资格证编号：" prop="licenseCode">
                  <el-input v-model="addForm.licenseCode" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="教师资格证：" prop="licenseImg">
                  <div class="hk-flex" style="align-items:flex-end;">

                    <div class="hk_upload_main license_upload">
                      <input class="hk_ipt_file" id="J_license_img" type="file" @change="changeUploadImgFun('J_license_img','TEACHER_LICENSE')" accept="image/*">
                      <div class="hk_upload_box" v-if="addForm.licenseImg">
                        <img :src="addForm.licenseImg" alt="">
                      </div>
                      <div class="hk_upload_box" v-else>
                        <div class="hk_upload-tag iconfont icon-jiahao"></div>
                        <p class="hk_upload-txt">上传图片</p>
                      </div>
                    </div>
                    <div class="hk-flex-item hk-font-tint" style="padding-left:8px;font-size:12px;line-height:20px;">
                      1.封面图片大小不超过5MB <br>
                      2.封面图片格式支持JPG,PNG,GIF</div>

                  </div>

                </el-form-item>
              </div>
              <div class="header-img-box">
                <div class="hk_upload_main header_upload">
                  <input class="hk_ipt_file" id="J_staff_img" type="file" @change="changeUploadImgFun('J_staff_img','TEACHER_IMG')" accept="image/*">
                  <div class="hk_upload_box" v-if="addForm.staffImg">
                    <img :src="addForm.staffImg" alt="">
                  </div>
                  <div class="hk_upload_box" v-else>
                    <div class="hk_upload-tag iconfont icon-jiahao"></div>
                    <p class="hk_upload-txt">上传头像</p>
                  </div>
                </div>
                <!-- <img :src="addForm.staffImg" alt=""> -->
                账号头像
              </div>
            </div>

            <div class="block-title">账号权限</div>
            <el-form-item label="账号角色：" prop="roleIdList">
              <el-select v-model="addForm.roleIdList" placeholder="请选择（支持多选）" multiple style="width: 100%;">
                <el-option :label="item.roleName" :value="item.roleId" :key="item.id" v-for="item in roleList">{{ item.roleName }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="负责学段：" prop="manageStageIdList">
              <el-checkbox-group v-model="addForm.manageStageIdList">
                <el-checkbox :label="item.id" :key="idx" v-for="(item,idx) in stageList">{{item.configName}}</el-checkbox>

              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="负责学科：" prop="manageSubjectIdList">
              <el-checkbox-group v-model="addForm.manageSubjectIdList">
                <el-checkbox :label="item.id" :key="idx" v-for="(item,idx) in subjectTypeObj">{{item.configName}}</el-checkbox>
              </el-checkbox-group>

            </el-form-item>

          </el-form>
          <!-- </div>
          <div class="header-img-box">
            <img src="../../assets/logo.png" alt="">
            企微头像
          </div> -->
        </div>

      </div>

    </div>
    <div class="hk-common-footer">
      <el-button size="small" @click="cancelAddFun">取消</el-button>
      <el-button size="small" type="primary" @click="confirmAddFun('addForm')">保存</el-button>
    </div>

  </div>
</template>

<script>
import { validateEmail, validateMobilePhone } from '../../utils/validate'
import { SchoolZoneEnum } from '../../utils/enums'
export default {
  components: {},
  data() {
    return {
      wxCpList: [], //企微员工列表，用于绑定员工

      editId: '', //编辑员工时的id
      addForm: {
        staffImg: 'https://oss.ke.live/images/default_head_img.png', //默认头像
        staffName: "", //classin姓名
        phone: "", //classin手机号
        wxCpUserId: '', //企微昵称
        // subjectType: '', //学科
        schoolZone: '', //校区
        college: '', //毕业院校，输入框
        // email: '', //
        licenseCode: '', //教室资格证，非必填
        licenseImg: '', //资格证照片
        // enwxQrImg: '', //企微二维码图片    
        roleIdList: [], //账号角色
        manageStageIdList: [], //负责学段，多选 小学 初中 高中
        manageSubjectIdList: [], //学科，多选

      },
      addRules: {
        phone: [
          { validator: validateMobilePhone, trigger: 'blur' }
        ],
        wxCpUserId: [
          { required: true, message: "请选择", trigger: "blur" }
        ],
        // subjectType: [
        //   { required: true, message: "请选择", trigger: "blur" }
        // ], //所教学科
        schoolZone: [
          { required: true, message: "请选择", trigger: "blur" }
        ], //校区
        college: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        // email: [
        //   { required: true, message: "请输入", trigger: "blur" },
        //   { validator: validateEmail, trigger: 'blur' }
        // ],
        roleIdList: [
          { required: true, message: "请选择", trigger: "blur" }
        ], //账号角色
        manageStageIdList: [
          { required: true, message: "请选择", trigger: "blur" }
        ],
        manageSubjectIdList: [
          { required: true, message: "请选择", trigger: "blur" }
        ],
      },
      roleList: [],//用到的角色列表下拉框
      schoolZoneList: SchoolZoneEnum,
      stageList: [], //学段列表
      subjectTypeObj: [], //学科列表

    }
  },
  mounted() {
    let that = this;

    that.getBaseConfigFun();
    let pageQuery = that.$route.query
    if (pageQuery.id) {
      that.editId = parseInt(pageQuery.id) //编辑员工
      that.getEditInfo(that.editId)
    }
    that.getWxCpUnbind()
    that.getAllRole()

  },

  watch: {

  },
  methods: {
    //获取上传的文件名称
    getFileName(file) {
      var pos = file.lastIndexOf("\\");
      return file.substring(pos + 1);
    },
    changeUploadImgFun(eleId, imgType) {
      let that = this;
      // that.ifShowUploading = true;

      let params = {
        ossPath: imgType,
        fileName: that.getFileName(document.getElementById(eleId).value)
      }
      let file = document.getElementById(eleId).files[0];

      that.request({
        method: 'post',
        url: '/tool/getOauthUrl',
        param: params,
        success: function (res) {

          var fileUrl = res.result; //返回的图片地址，和xhr.responseURL一样

          var xhr = new XMLHttpRequest();
          xhr.open("PUT", fileUrl, true);
          // 注意不要传入Content-Type，否则会出现签名错误
          xhr.setRequestHeader('Content-Type', '');
          xhr.onload = function () {

            if (xhr.readyState == 4) {
              let backResponse = xhr.responseURL; //直接返回的图片url,图片处理域名并截取？之前地址
              // https://kelivemanage.oss-cn-beijing.aliyuncs.com/pre/product/2024/20240919115613101.jpg?Expires=1726718474&OSSAccessKeyId=LTAI5tBc2wB3JcdbJhrXgpXC&Signature=Us%2FGfiJ19QshtXQHUuqelpsK1P8%3D

              let endIdx = backResponse.indexOf('?')
              let imgUrl = backResponse.substring(0, endIdx)
              let newUrl = imgUrl.replace('//kelivemanage.oss-cn-beijing.aliyuncs.com', '//oss.ke.live')
              console.log(newUrl)

              if (imgType == 'TEACHER_LICENSE') {
                //教师资格证
                that.$set(that.addForm, 'licenseImg', newUrl)
                if (window.localStorage) { //存储起来，避免保存失败或意外退出后，需重新上传
                  localStorage.setItem('hk_employee_licenseImg', newUrl)
                }
              } else {
                //头图
                that.$set(that.addForm, 'staffImg', newUrl)
                if (window.localStorage) {
                  localStorage.setItem('hk_employee_staffImg', newUrl)
                }
              }
              // that.ifShowUploading = false;
              document.getElementById(eleId).value = '';
            }
          };
          xhr.send(file);

        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('上传失败');
        }
      })
    },
    //获取角色列表
    getAllRole() {
      let that = this;
      that.request({
        method: 'post',
        url: '/manage/staff/listRole',
        param: {},
        success: function (result) {
          if (result.code == 200) {
            that.roleList = result.result || [];
          } else {
            console.log(result, '----角色 not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取角色失败。')
        }
      })
    },

    //获取基本配置信息，目前不知道在哪里用
    getBaseConfigFun() {
      let that = this;
      that.request({
        method: 'post',
        url: '/manage/base/listConfig',
        param: {},
        success: function (res) {
          // console.log(res, '------获取配置---')

          if (res.code == 200 && res.result) {
            that.stageList = res.result.STAGE_TYPE || []
            that.subjectTypeObj = res.result.SUBJECT_TYPE || []
            console.log(that.stageList, '------that.stageList---', that.subjectTypeObj)

          } else {
            that.$message.error('获取失败--' + res.msg)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('获取基本配置异常');
        }
      })
    },

    //获取编辑的员工信息
    getEditInfo(id) {
      let that = this;
      that.request({
        method: 'post',
        url: '/manage/staff/getDetailStaffInfoById',
        param: { id: id },
        success: function (res) {
          // console.log(res, '------获取员工信息---')
          if (res.code == 200) {
            let tmpInfo = res.result || {}
            that.addForm = Object.assign({}, {
              staffImg: tmpInfo.staffImg || 'https://oss.ke.live/images/default_head_img.png',
              staffName: tmpInfo.staffName, //classin姓名
              phone: tmpInfo.phone, //classin手机号
              wxCpUserId: tmpInfo.wxCpUserId, //企微昵称
              subjectType: tmpInfo.subjectType, //学科
              schoolZone: tmpInfo.schoolZone, //校区
              college: tmpInfo.college, //毕业院校，输入框
              email: tmpInfo.email, //
              licenseCode: tmpInfo.licenseCode, //教室资格证，非必填
              licenseImg: tmpInfo.licenseImg, //资格证照片
              // enwxQrImg: '', //企微二维码图片    
              roleIdList: tmpInfo.roleIdList || [], //账号角色
              manageStageIdList: tmpInfo.manageStageIdList || [], //负责学段，多选 小学 初中 高中
              manageSubjectIdList: tmpInfo.manageSubjectIdList || [], //学科，多选

            })

            //roleIdList为空，编辑时无法赋值
            if (!tmpInfo.roleIdList && tmpInfo.roleList && tmpInfo.roleList.length) {
              console.log('roleIdList没有数据，自己组合')
              let tmpCustomRole = []
              tmpInfo.roleList.forEach(role => {
                tmpCustomRole.push(role.roleId)
              })
              that.$set(that.addForm, 'roleIdList', tmpCustomRole)
            }

          } else {
            that.$message.error('获取失败--' + res.msg)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('获取员工信息异常');
        }
      })
    },

    //获取企微员工列表
    getWxCpUnbind() {
      let that = this;
      that.request({
        method: 'post',
        url: '/manage/wxCp/listUserForBind',
        param: {},
        success: function (res) {
          console.log(res, '------获取列表---')
          if (res.code == 200) {
            that.wxCpList = res.result || []

          } else {
            that.$message.error('获取失败--' + res.msg)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('获取异常');
        }
      })
    },
    // 保存员工信息
    confirmAddFun(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let params = Object.assign({}, that.addForm)
          // console.log(that.addForm.roleIdList,'-------that.addForm')
          let url = '/manage/staff/addStaff'
          if (that.editId) { //编辑
            url = '/manage/staff/updateStaff'
            params.id = that.editId
          }
          that.request({
            method: 'post',
            url: url,
            param: params,
            success: function (res) {
              console.log(res, '------添加员工成功---')

              if (res.code == 200) {
                that.$message.success('保存成功！')
                that.$router.go(-1)

              } else {
                that.$message.error('保存失败--' + res.msg)
              }
            },
            error: function (error) {
              //服务器异常
              console.log(error);
              that.$message.error('保存异常');
            }
          })
        } else {
          // that.$message('先填写信息')
        }
      });


    },
    // 添加OR编辑 员工弹窗--取消-----隐藏弹窗，并清空弹窗form中的数据
    cancelAddFun() {

      this.editId = ''
      this.$router.go(-1)

    },
  }

}
</script>

<style lang="scss" scoped>
.hk_page_wrap {
  padding-bottom: 60px;
}
.add-block {
  .block-title {
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    line-height: 24px;
    margin-bottom: 16px;
    position: relative;
    padding-left: 4px;
    &::before {
      position: absolute;
      content: "";
      left: -9px;
      top: 0;
      bottom: 0;
      width: 4px;
      // height: 24px;
      background: #ff6010;
      border-radius: 2px;
    }
  }
  .el-input,
  .el-select {
    max-width: 350px !important;
  }
  .account-item {
    border-radius: 11px;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    padding: 4px 12px;
    margin-right: 10px;
  }
  .account-normal {
    background: rgba(82, 196, 26, 0.1);
    border: 1px solid #52c41a;
    color: #52c41a;
  }
  .account-stop {
    background: rgba(219, 56, 67, 0.1);
    border: 1px solid #db3843;
    color: #db3843;
  }
  .header-img-box {
    margin-left: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #606060;
    line-height: 22px;
    text-align: center;

    //头像上传
    .header_upload {
      width: 110px;
      height: 110px;
      border: 0;
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #eaeaea;
        margin-bottom: 5px;
        display: block;
      }
    }
  }

  // 资格证上传
  .license_upload {
    width: 170px;
    height: 105px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>